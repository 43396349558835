.mainContainer__toolbar {
  background-color: #ffffff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  /* padding-left: none !important; */
  box-shadow: none !important;
}

.MuiDrawer-paperAnchorLeft {
  border-right: none !important;
}
