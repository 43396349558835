.popupComponent__modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popupComponent__paper {
  width: 70%;
  min-height: 80%;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  outline: 0;
}

.popupComponent__topNav {
  display: flex;
  justify-content: space-between;
}

.popupComponent__topNav--right {
  display: flex;
}
