/* Board css */
.noteContainer__board {
  display: flex;
  flex-direction: column;
  padding: 20px 0px 0px 10px;
}

.noteContainer__column {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  width: 1100px;
  /* border-right: 1px solid rgb(236, 238, 240); */
}

.noteContaioner__columnTitle {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.noteContaioner__columnTitle--empty {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 10px;
  color: var(--maingrey-color);
}
.noteContainer__card {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  height: 40px;
  border-radius: 8px;
  font-size: 1rem;
  /* margin: 4px 0px; */
  padding-left: 10px;
}
.noteContainer__card:hover {
  background-color: var(--secondarygrey-color);
  cursor: pointer;
}

.noteContainer__card--topSection {
  display: flex;
  justify-content: space-between;
}

.noteContainer__cardTopSectionLeft {
  display: flex;
}

.noteContainer__card--title {
  font-weight: 400;
  width: 120px;
}

.noteContainer__card--time {
  font-size: 0.7rem;
}

.noteContainer__card--header {
  font-weight: 700;
  padding-right: 4px;
}

.noteContainer__card--body {
  font-weight: 400;
}

.noteContainer__midSection {
  padding-bottom: 10px;
}

/* List css */

/* Note css */
