/* Sidebar add */

.addNewButton {
  display: flex;
  align-items: center;
  padding: 2px 20px;
  width: 95%;
  height: 35px;
  border-radius: 25px;
  color: var(--maingrey-color);
}

.addNewButton:hover {
  background-color: var(--secondarygrey-color);
  cursor: pointer;
}

/* Card add  */

.addNewCardButton {
  display: flex;
  align-items: center;
  padding: 2px 5px;
  margin-top: 5px;
  height: 40px;
  border-radius: 8px;
  color: var(--maingrey-color);
}

.addNewCardButton:hover {
  background-color: var(--secondarygrey-color);
  cursor: pointer;
}

/* Column add  */

.addNewColumnButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  margin-left: 10px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  color: var(--maingrey-color);
  background-color: var(--secondarygrey-color);
}

.addNewColumnButton:hover {
  cursor: pointer;
}
