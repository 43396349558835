.CardBadge__container {
  display: flex;
  align-items: center;
  /* padding: 2px 15px; */
  width: 95%;
  border-radius: 25px;
  /* font-weight: 600; */
}

.CardBadge__container:hover {
  background-color: var(--secondarygrey-color);
  cursor: pointer;
}

.CardBadge__icon {
  display: flex;
  align-items: center;
  margin-left: 7px;
  margin-right: 7px;
  padding: 3px;
  border-radius: 8px;
}

.CardBadge__materialicon {
  font-size: 1.2rem !important;
}

.CardBadge__title {
  display: flex !important;
  align-items: center !important;
  color: var(--maingrey-color);
  font-weight: 400 !important;
  font-size: 0.9rem;
}
