.iconButton__container {
  display: flex;
  align-items: center;
  padding: 3px;
  /* padding-right: 10px; */
  border-radius: 8px;
  font-weight: 600;
}

.iconButton__container:hover {
  background-color: var(--secondarygrey-color);
  cursor: pointer;
}

.iconButton__icon {
  display: flex;
  align-items: center;
  padding: 3px;
  border-radius: 8px;
}

.iconButton__materialicon {
  color: var(--maingrey-color);
  transform: scale(0.75);
  /* color: white; */
}

.iconButton__title {
  /* padding: 0px 3px; */
}
