/* Global styling */

body {
  --brand-color: #de3127;
  --primary-color: #110302;
  --maingrey-color: rgb(183, 188, 191);
  --secondarygrey-color: #f5f5f5;
  --main-background: #ffffff;
  --secondary-background: #f7f6f2;
  background-color: #ffffff !important;
  /* font-family: "Poppins", sans-serif !important; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.app__body {
  display: flex;
  height: 100vh;
}

/* Fonts  */
