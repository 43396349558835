.bodyContainer__container {
  display: flex;
  flex-direction: column;
}

.bodyContainer__boardSection {
  margin-bottom: 50px;
}
.bodyContainer__title {
  display: flex;
  align-items: center;
  padding: 2px 15px;
  /* width: 95%; */
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: 600;
}

.bodyContainer__title:hover {
  /* background-color: #e4e4e4; */
  cursor: pointer;
}
