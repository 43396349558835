/* Board css */
.sheetContainer__board {
  display: flex;
  padding: 20px 0px 0px 10px;
}

.sheetContainer__column {
  display: flex;
  flex-direction: column;
  padding: 0px 5px;
  /* width: 300px; */
}

.sheetContaioner__columnTitle {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.sheetContaioner__columnTitle--small {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px !important;
  font-weight: 800;
  height: 34px;

  margin-bottom: 10px;
  color: var(--primary-color);
}

.sheetContaioner__columnTitle--empty {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 10px;
  height: 34px;

  color: var(--maingrey-color);
}
.sheetContainer__card {
  display: flex;
  flex-direction: column;
  min-height: 40px;
  border-radius: 8px;
  margin: 4px 0px;
  padding: 10px;
  background-color: var(--secondarygrey-color);
}

.sheetContainer__cardImageBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  min-height: 40px;
  border-radius: 8px;
  margin: 4px 0px;
  /* padding: 10px; */
  background-color: var(--secondarygrey-color);
}

.sheetContainer__cardImage {
  display: flex;
  object-fit: contain;
  height: 32px;
  width: 32px;

  border-radius: 100px;
}

.sheetContainer__cardTag {
  display: inline-flex;
  width: fit-content;
  background-color: #de3127;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 10px;
  margin-right: 10px;
}

/* List css */

/* Note css */
