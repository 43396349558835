.SidebarCategory__title {
  position: relative;
  text-transform: uppercase;
  font-size: 13px;
  padding-left: 27px;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  margin-bottom: 6px;
  font-weight: bold;
  color: rgb(75, 81, 85);
}
