.BoardName__container {
  display: flex;
  align-items: center;
  padding: 2px 15px;
  width: 95%;
  border-radius: 25px;
  font-weight: 600;
}

.BoardName__container:hover {
  background-color: var(--secondarygrey-color);
  cursor: pointer;
}

.BoardName__icon {
  display: flex;
  align-items: center;
  margin-left: 7px;
  margin-right: 7px;
  padding: 3px;
  border-radius: 8px;
}

.BoardName__materialicon {
  font-size: 1.2rem !important;
}
