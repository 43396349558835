/* Board css */
.boardContainer__board {
  display: flex;
  padding: 20px 0px 0px 10px;
}

.boardContainer__column {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  width: 300px;
  border-right: 1px solid rgb(236, 238, 240);
}

.boardContaioner__columnTitle {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.boardContaioner__columnTitle--empty {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 10px;
  color: var(--maingrey-color);
}
.boardContainer__card {
  display: flex;
  flex-direction: column;
  min-height: 40px;
  border-radius: 8px;
  margin: 4px 0px;
  padding: 10px;
  background-color: var(--secondarygrey-color);
}

.boardContainer__card--topSection {
  display: flex;
  justify-content: space-between;
}

.boardContainer__card--title {
  font-weight: 700;
}

.boardContainer__card--time {
  font-size: 0.7rem;
}

.boardContainer__card--header {
  font-weight: 700;
  padding-right: 4px;
}

.boardContainer__midSection {
  padding-bottom: 10px;
}

/* List css */

/* Note css */
