.mailItem__container {
  display: flex;
  direction: row;
  justify-content: space-between;
  /* background-color: red; */
  padding: 3px 8px;
  border-radius: 8px;
  margin: 3px 0px;
  height: 55px;
}
.mailItem__container:hover {
  background-color: #ededed;
  transition: 100ms ease-in;
  cursor: pointer;
}

.mailItem__left {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding-left: 10px; */
}

.mailItem__right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mailItem__cardTitle {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600;
}

.mailItem__cardBody {
  display: flex;

  font-weight: 400;
}

.mailItem__cardBody > div {
  padding-right: 5px;
}

.mailItem__avatar > img {
  display: flex;
  align-items: center;
  margin-right: 12px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.mailItem__cardText {
  color: #696969 !important;
}

.mailItem__timestamp {
  font-size: 0.7rem;
  color: #696969;
  padding-right: 10px;
}
