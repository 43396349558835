.sidebarIcon__container {
  display: flex;
  align-items: center;
  padding: 3px 15px;
  border-radius: 25px;
  font-weight: 600;
}

.sidebarIcon__container:hover {
  background-color: var(--secondarygrey-color);
  cursor: pointer;
}

.sidebarIcon__icon {
  display: flex;
  align-items: center;
  margin-left: 7px;
  margin-right: 7px;
  padding: 3px;
  border-radius: 8px;
}

.sidebarIcon__materialicon {
  color: var(--maingrey-color);
  transform: scale(0.75);
  /* color: white; */
}
