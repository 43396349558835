/* Board css */
.listContainer__board {
  display: flex;
  flex-direction: column;
  padding: 20px 0px 0px 10px;
}

.listContainer__column {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  width: 1100px;
  /* border-right: 1px solid rgb(236, 238, 240); */
}

.listContaioner__columnTitle {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.listContaioner__columnTitle--empty {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 10px;
  color: var(--maingrey-color);
}
.listContainer__card {
  display: flex;
  flex-direction: column;
  min-height: 40px;
  border-radius: 8px;
  margin: 4px 0px;
  padding: 10px;
  background-color: var(--secondarygrey-color);
}

.listContainer__card--topSection {
  display: flex;
  justify-content: space-between;
}

.listContainer__cardTopSectionLeft {
  display: flex;
}

.listContainer__card--title {
  font-weight: 700;
  width: 120px;
}

.listContainer__card--time {
  font-size: 0.7rem;
}

.listContainer__card--header {
  font-weight: 700;
  padding-right: 4px;
}

.listContainer__card--body {
  font-weight: 400;
}

.listContainer__midSection {
  padding-bottom: 10px;
}

/* List css */

/* Note css */
